* {
    padding: 0;
    margin: 0;
}

body {
    background-color: #0b1e37;
}

.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    color: white;
    gap: 15px;
}

.logo {
    height: 150px;
    border-radius: 35px;
}

.contenido {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}